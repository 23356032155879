/*TODO: make light variant*/
:root.theme-yooma_earth-light {
	--fg-c1: #444343;
	--bg-c1: #e2d9d3;
	--text-c1: #4e4e4e;

	--fg-c2: #345b37;
	--bg-c2: #f4ede8;
	--text-c2: #464646;

	--fg-c3: #18431b;
	--bg-c3: darkgrey;
	--text-c3: #346334;

	--fg-c4: #414042;
	--bg-c4: #B58863;
	--text-c4: #f6f6f6;

	--fg-c5: #B58863;
	--bg-c5: #414141;
	--text-c5: #2c7f71;

	--fg-c6: #1c695c;
	--bg-c6: #B58863;

	--border-1: #f4ede8;
	--border-2: #a07a5f;
	--border-3: #a38c7d;
	--border-4: #e2d9d3;

	--bg-shadow: #676565f2;

	--fg-warning: #5f3f07;
	--fg-danger: #8a0905;
	--fg-success: #4d714d;
	--fg-default: #B58863;
	--fg-primary: #B58863;

	--fa-primary-color: #4e4f52;
	--fa-secondary-color: #a878a7;

	--fc-btn-primary: #a878a7;
}
