.ps-table-no-border {
	text-align: start;
	max-width: 100%;
	width: 100%;
	border: none;
	background-color: transparent;
}

.ps-table-no-border-yooma {
	text-align: start;
	max-width: 100%;
	width: 100%;
	border: none;
	background-color: transparent;
}

.ps-table-no-border-yooma thead th {
	background-color: var(--fg-c3);
	color: #ffffff;
	padding: 0.75rem;
	font-weight: bold;
}

.ps-table-no-border-yooma tbody tr:hover {
	background-color: var(--fg-c5);
	color: var(--text-c4);
}

.ps-table-no-border-yooma tbody td {
	padding: 0.75rem;
}


.ps-table-yooma {
		font-size: 1fr;
		border-collapse: collapse;
		width: 100%;
		font-family: var(--font-primary);
		color: var(--text-c1);
		background-color: var(--bg-c1);
	}
	
	.ps-table-yooma thead th {
		font-size: 1.2em;
		font-weight: bold;
		background-color: var(--fg-c3);
		border: 1px solid black;
		padding: 0.75rem;
		text-align: left;
		caret-color: transparent;
		color: black;
	}
	
	.ps-table-yooma tbody td {
		border: 1px solid var(--border-3);
		padding: 0.75rem;
	}
	
	.ps-table-yooma tfoot td {
		border: 1px solid var(--border-3);
		padding: 0.75rem;
		font-weight: bold;
	}
	
	.ps-table-yooma tbody tr:nth-child(even) {
		background-color: var(--bg-c2);
	}
	
	.ps-table-yooma tfoot tr:nth-child(even) {
		background-color: var(--bg-c2);
	}
	
	.ps-table-yooma tbody tr:hover {
		background-color: var(--fg-c5)!important;
		color: black;
	}
	
	.ps-table-yooma tfoot tr:hover {
		background-color: var(--fg-c5) !important;
		color:  black;
	}
	
	.ps-table-yooma tbody tr.select {
		background-color: var(--bg-c4);
	}
	
	.ps-table-yooma tfoot tr.select {
		background-color: var(--bg-c4);
	}


