/*TODO: make dark variant*/
:root.theme-yooma_default-dark {
	--fg-c1: #444343;
	--bg-c1: #fafafa;
	--text-c1: #4e4e4e;

	--fg-c2: #989898;
	--bg-c2: #ffffff;
	--text-c2: #464646;

	--fg-c3: #acacac;
	--bg-c3: darkgrey;
	--text-c3: #556a6f;

	--fg-c4: #414042;
	--bg-c4: #cbcbcb;
	--text-c4: #FFFFFF;

	--fg-c5: #75bed5;
	--bg-c5: #414141;
	--text-c5: #13a7cf;

	--fg-c6: #0d7c97;
	--bg-c6: #f1f1f1;

	--border-1: #e3e1db;
	--border-2: #6c6859;
	--border-3: #d2d2d2;
	--border-4: #efefef;

	--bg-shadow: #676565f2;

	--fg-warning: #d59945;
	--fg-danger: #91403e;
	--fg-success: #5d9d5d;
	--fg-default: #585e68;
	--fg-primary: #81a6bf;

	--fa-primary-color: #4e4f52;
	--fa-secondary-color: #c3b99a;

	--fc-btn-primary: #9d9479;
}