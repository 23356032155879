/*TODO: make light variant*/
:root.theme-savanna_classic-light {
	--fg-c1: #444343;
	--bg-c1: #fafafa;
	--text-c1: #4e4e4e;

	--fg-c2: #31859D;
	--bg-c2: #ffffff;
	--text-c2: #464646;

	--fg-c3: #31859D;
	--bg-c3: darkgrey;
	--text-c3: #77933C;

	--fg-c4: #414042;
	--bg-c4: #cbcbcb;
	--text-c4: #FFFFFF;

	--fg-c5: #77933C;
	--bg-c5: #414141;
	--text-c5: #86c7d9;

	--fg-c6: #6db0c2;
	--bg-c6: #d0d0d0;

	--border-1: #E1F2CE;
	--border-2: #31708f;
	--border-3: #d6d6d6;
	--border-4: #f1f1f1;

	--bg-shadow: #676565f2;

	--fg-warning: #f0ad4e;
	--fg-danger: #c9302c;
	--fg-success: #5cb85c;
	--fg-default: #77933c;
	--fg-primary: #3498db;

	--fa-primary-color: #4e4f52;
	--fa-secondary-color: #ffc928;

	--fc-btn-primary: #31859D;
}