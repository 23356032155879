/*TODO: make dark variant*/
:root.theme-yooma_pink-dark {
	--fg-c1: #444343;
	--bg-c1: #fafafa;
	--text-c1: #4e4e4e;

	--fg-c2: #ec008c;
	--bg-c2: #ffffff;
	--text-c2: #464646;

	--fg-c3: #f273ab;
	--bg-c3: darkgrey;
	--text-c3: #9722a2;

	--fg-c4: #414042;
	--bg-c4: #cbcbcb;
	--text-c4: #FFFFFF;

	--fg-c5: #eec030;
	--bg-c5: #414141;
	--text-c5: #d481df;

	--fg-c6: #b563bf;
	--bg-c6: #f1f1f1;

	--border-1: #d9edf7;
	--border-2: #31708f;
	--border-3: #dee2e6;
	--border-4: #f1f1f1;

	--bg-shadow: #676565f2;

	--fg-warning: #f0ad4e;
	--fg-danger: #c9302c;
	--fg-success: #5cb85c;
	--fg-default: #E3CB51;
	--fg-primary: #3498db;

	--fa-primary-color: #4e4f52;
	--fa-secondary-color: #ffc928;

	--fc-btn-primary: #b563bf;
}