/*NOTE: WORK IN PROGRESS*/
.ps-dashboard-dashboard {
	font-family: var(--font-primary);
	color: var(--fg-c1);
	background-color: var(--bg-c1);
}

.ps-dashboard-title {
	font-size: 1.5em;
}

.a.ps-dashboard-action {
	color: var(--fg-c1);
	text-decoration: none;
}

.a.ps-dashboard-action:hover {
	color: var(--fg-c1);
	text-decoration: underline;
}