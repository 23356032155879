/*NOTE: WORK IN PROGRESS*/
.ps-input {
	font-family: var(--font-primary);
	color: var(--fg-c1);
	height: 2.25em;
	padding: 0px 8px 0px 8px;
	border: 0.05em solid var(--border-4);
	border-radius: 0.5em;
	font-size: 1em;
	width: 100%;
	background-color: transparent;
	box-shadow: 0 0.1em 0.2em rgba(0, 0, 0, 0.1);
	transition: border-color 0.3s, box-shadow 0.3s;
}

.ps-input:focus {
	box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
	outline: 0.15em solid var(--fg-primary);
}

input[type="checkbox"].ps-input:focus {
	box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
	outline: none !important;
}

input[type="checkbox"].ps-input {
	width: auto;
}

input[disabled="true"].ps-input {
	opacity: 0.5; 
	cursor: not-allowed;
}

.ps-input-no-border {
	font-family: var(--font-primary);
	color: var(--fg-c1);
	height: 2.25em;
	padding: 0px 8px 0px 8px;
	border-radius: 0.5em;
	font-size: 1em;
	background-color: transparent;
	border: 0;
	background: transparent;
	box-shadow: 0 0;
	transition: border-color 0.3s, box-shadow 0.3s;
}

.ps-input-no-border:focus {
	border: none !important;
	box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
	outline: 0.15em solid var(--fg-primary);
}

.ps-input-no-border:hover {
	border: none !important;
	box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
	outline: 0.15em solid var(--fg-primary);
}

/*Prevents yellow input background when browser uses autofill*/
.ps-input:-webkit-autofill,
.ps-input:-webkit-autofill:hover,
.ps-input:-webkit-autofill:focus,
.ps-input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 50px var(--bg-c1) inset;
	box-shadow: 0 0 0 50px var(--bg-c1) inset;
	-webkit-text-fill-color: var(--fg-c1);
}

/*Sets ps-input textarea vertical alignment in Chrome to the same as Firefox*/
.ps-input-group > textarea.ps-input {
	vertical-align: middle;
}

textarea.ps-input {
	padding: 4px 8px 4px 8px;
}

.ps-divider {
	height: 1px;
	margin: 0.5em 0;
	overflow: hidden;
	background-color: var(--border-4);
}

.ps-invalid-input {
	box-shadow: 0px 0px 2px 1.5px rgb(255 0 0 / 76%);
	border: 2px solid rgba(224, 9, 9, 0.76);
}

/* ps-date-input-group */
/* styling for grouped input group used for from/to date*/
.ps-date-input-group {
	display: inline-flex;
	gap: 10px;
	align-items: center;
	margin-inline: 1em;
}

.ps-date-input-group div {
	display: flex;
	align-items: center;
	background-color: var(--bg-c2);
	border: 1px solid var(--border-2);
	border-radius: 8px;
	padding: 10px;
	caret-color: transparent;
	width: 100%;
}

.ps-date-input-group div input {
	border: none;
	background-color: transparent;
	flex: 1;
	font-size: 1rem;
	box-shadow: none;
}

.ps-date-input-group div span {
	padding: 0 10px;
	border-right: 1.5px solid var(--border-2);
}

/* ps-checkbox */
.ps-checkbox {
	display: inline-flex;
	align-items: center;
	cursor: pointer;
	font-family: 'Arial', sans-serif;
	user-select: none;
}

.ps-checkbox input {
	display: none;
}

.ps-checkbox span {
	display: inline-block;
	width: 1em;
	height: 1em;
	border: 0.1em solid var(--bg-c5);
	background-color: var(--fg-c5);
	border-radius: 50%;
	vertical-align: middle;
	margin-right: 1em;
	position: relative;
	box-shadow: 0 0.2em 0.4em rgba(0, 0, 0, 0.35);
}

.ps-checkbox input:checked + span::before {
	content: '\2713';
	font-size: 1.3em;
	color: var(--text-c5);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

/* 
	ps-element-group is useful to mostly put any element in an inline group 
	and have it merged into one element
*/
.ps-element-group {
	display: flex;
	border-radius: 0;
	outline: none;
}

.ps-element-group >:focus {
	overflow: auto !important;
}

.ps-element-group button {
	border-radius: 0;
	cursor: pointer;
}

.ps-element-group > :not(ul) {
	border-radius: 0;
}

.ps-element-group > :first-child:not(ul) {
	border-radius: 10px 0 0 10px;
}

.ps-element-group > :last-child:not(ul) {
	border-radius: 0 10px 10px 0;
}

.ps-element-group > *:last-child > button:only-of-type {
	border-radius: 0 10px 10px 0 !important;
	height: 100%;
}

.ps-element-group > *:first-child > button:only-of-type {
	border-radius: 10px 0 0 10px !important;
	height: 100%;
}

.ps-element-group .ps-dropdown button{
	border-radius: 0;
	padding: 0.44em;
}

.ps-bordered-block {
	height: 2.25em;
	padding: 0px 8px 0px 8px;
	background-color: var(--bg-c2);
	border: 1px solid var(--border-4);
	border-radius: 4px;
	color: var(--text-c1);
	line-height: 2.25em;
	white-space: nowrap;
}
