/**
 * Default variable settings for colours in the system
 */
:root {
	--fg-c1: rgba(0, 0, 0, 0.64);
	--bg-c1: #f5f5f5;
	--text-c1: #4e4e4e;

	--fg-c2: purple;
	--bg-c2: white;
	--text-c2: purple;

	--fg-c3: blue;
	--bg-c3: #cecece;
	--text-c3: #E6F7FE;

	--fg-c4: rgb(26, 24, 24);
	--bg-c4: #e4e4e4;
	--text-c4: #2c752c;

	--fg-c5: #fdfdfd;
	--bg-c5: black;
	--text-c5: black;

	--border-1: darkgrey;
	--border-2: #e2f0d9;
	--border-3: #dee2e6;
	--border-4: #b2b3b4;

	--fg-warning: #f0ad4e;
	--fg-danger: #c9302c;
	--fg-success: #5cb85c;
	--fg-default: #fff;
	--fg-primary: #3498db;

	--bg-warning: #f0ad4e;
	--bg-danger: #c9302c;
	--bg-success: #5cb85c;
	--bg-default: #fff;
	--bg-primary: #3498db;

	--fa-primary-color: #4e4f52;
	--fa-secondary-color: #8f96a1;

	--font-family: "Lato", sans-serif; 
}
