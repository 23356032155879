/* ps-dropdown */
.ps-dropdown.open > ul {
	display: block;
}

.ps-dropdown span.ps-dropdown-text {
	float: left;
	width: 97%;
	overflow: hidden;
	text-align: left;
}


.ps-dropdown span.ps-dropdown-text.ps-dropdown-placeholder {
	opacity: 0.5;
}

.ps-dropdown > ul > li > a {
	display: block;
	padding: 3px 20px;
	clear: both;
	font-weight: 400;
	line-height: 1.42857143;
	color: var(--text-c1);
	white-space: nowrap;
}

.ps-dropdown > ul > li > a:focus, .ps-dropdown > ul > li > a:hover {
	text-decoration: none;
	background-color: var(--bg-c1);
	color: var(--text-c1);
}

.ps-dropdown ul {
	position: absolute;
	display: none;
	max-height: 25em;
	overflow-y: scroll;
	z-index: 1000;
	padding: 5px 0;
	margin: 2px 0 0;
	list-style: none;
	background-color: var(--bg-c2);
	background-clip: padding-box;
	border: 1px solid var(--border-2);
	border-radius: 4px;
	box-shadow: 0 6px 12px rgba(0,0,0,.175);
}

/*Remove left borders for ps-input for dropdown*/
.ps-input-group .ps-dropdown button {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	padding: 0.4em;
}

.ps-dropdown button {
	text-wrap: nowrap;
}

.ps-dropdown ul li a {
	padding: 8px 12px;
}

.ps-dropdown ul li {
	cursor: pointer;
}

.ps-dropdown ul li a.selected {
	background-color: var(--fg-primary) !important;
	color: var(--text-c1);
}

.ps-dropdown ul li a.selected:hover {
	background-color: var(--fg-primary) !important;
	transition: background-color 0.2s;
	color: var(--bg-c2);
}

.ps-dropdown-header {
	font-weight: bold;
	padding: 0.5em 1em;
}
