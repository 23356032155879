:root.theme-scm-light {
	--fg-c1: #000;
	--bg-c1: #fdfdfd;
	--text-c1: #4e4e4e;

	--fg-c2: #7d56a1;
	--bg-c2: white;
	--text-c2: #212a2e;

	--fg-c3: #4a90e2;
	--bg-c3: #cccccc;
	--text-c3: #e7e7e7;

	--fg-c4: rgb(230, 230, 230);
	--bg-c4: #9a9a9b;
	--text-c4: #757575;

	--fg-c5: #fdfdfd;
	--bg-c5: black;
	--text-c5: #424242;

	--fg-c6: #ececec;
	--bg-c6: #acacac36;

	--border-1: #bebebe;
	--border-2: #bebebe;
	--border-3: #bebebe;
	--border-4: #bebebe;

	--bg-shadow: rgb(197, 193, 193);

	--fg-warning: #ecb51f;
	--fg-danger: #ee2a27;
	--fg-success: #50ba69;
	--fg-default: #fff;
	--fg-primary: #2bace2;

	--bg-warning: #ecb51f;
	--bg-danger: #ee2a27;
	--bg-success: #50ba69;
	--bg-default: #fff;
	--bg-primary: #2bace2;

	--fa-primary-color: #26c4f4;
	--fa-secondary-color: #26c4f4;

	--font-family: "Lato", sans-serif; 
}