/*TODO: make dark variant*/
:root.theme-yooma_blue-dark {
	--fg-c1: #444343;
	--bg-c1: #f1f1f1;
	--text-c1: #4e4e4e;

	--fg-c2: #21c4f5;
	--bg-c2: #ffffff;
	--text-c2: #464646;

	--fg-c3: #00aeef;
	--bg-c3: darkgrey;
	--text-c3: #7a7a7a;

	--fg-c4: #414042;
	--bg-c4: #cbcbcb;
	--text-c4: #ffffff;

	--fg-c5: #b563bf;
	--bg-c5: #414141;
	--text-c5: #fad05b;

	--fg-c6: #ecb41f;
	--bg-c6: #f1f1f1;

	--border-1: #d9edf7;
	--border-2: #31708f;
	--border-3: #dee2e6;
	--border-4: #f1f1f1;

	--bg-shadow: #676565f2;

	--fg-warning: #f0ad4e;
	--fg-danger: #c9302c;
	--fg-success: #5cb85c;
	--fg-default: #E3CB51;
	--fg-primary: #3498db;

	--fa-primary-color: #4e4f52;
	--fa-secondary-color: #ffc928;

	--fc-btn-primary: #4195ad; 
}